import React from 'react'
import { Route, NavLink } from 'react-router-dom'
import Top from 'components/Top'
import Container from 'components/Container'
import { Wrapper, ToC, Content } from 'components/Tutoriais'

import IntroText from './pages/intro'
import MedindoText from './pages/medindo'
import ImprimindoText from './pages/imprimindo'
import FixandoText from './pages/fixando'
import PosicionandoText from './pages/posicionando'
import TestandoText from './pages/testando'
import ManutencaoText from './pages/manutencao'
import AnaliseText from './pages/analise'

const ConhecaPage = () => (
  <div>
    <Top>
      <Container>
        <Top.Title>Conheça Melhor</Top.Title>
        <Top.SubTitle>Saiba desde a preparação do ambiente até a realização do teste.</Top.SubTitle>
      </Container>
    </Top>
    <Container>
      <Wrapper>
        <ToC>
          <ol>
            <li><NavLink to="/conheca/" exact>1. Iniciando</NavLink></li>
            {/* <li><NavLink to="/conheca/medindo">2. Faça as Medidas</NavLink></li> */}
            <li><NavLink to="/conheca/imprimindo">2. Imprima as Tabela</NavLink></li>
            <li><NavLink to="/conheca/fixando">3. Fixe as Tabelas</NavLink></li>
            <li><NavLink to="/conheca/posicionando">4. Posicione o Paciente</NavLink></li>
            <li><NavLink to="/conheca/testando">5. Realize o Teste</NavLink></li>
            <li><NavLink to="/conheca/manutencao">6. Manutenção das Tabelas</NavLink></li>
            <li><NavLink to="/conheca/analise">7. Analisando os Resultados</NavLink></li>
          </ol>
        </ToC>
        <Content>
          <Route path="/conheca" component={IntroText} exact />
          <Route path="/conheca/medindo" component={MedindoText} exact />
          <Route path="/conheca/imprimindo" component={ImprimindoText} exact />
          <Route path="/conheca/fixando" component={FixandoText} exact />
          <Route path="/conheca/posicionando" component={PosicionandoText} exact />
          <Route path="/conheca/testando" component={TestandoText} exact />
          <Route path="/conheca/manutencao" component={ManutencaoText} exact />
          <Route path="/conheca/analise" component={AnaliseText} exact />
        </Content>
      </Wrapper>
    </Container>
  </div>
)

export default ConhecaPage