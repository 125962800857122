import React from 'react'
import { Typography, H2, P, OL } from 'components/Typography'

const PosicionandoText = () => (
  <Typography>
    <H2>Condições do Ambiente para o Exame</H2>

    <P>Alguns pontos devem ser observados para uma maior acurácia do teste:</P>

    <OL>
      <li>O ambiente deve ser iluminado de forma a não causar penumbra nem reflexos excessivos;</li>
      <li>A parede onde a tabela se encontra afixada não deve apresentar janelas que causem reflexos no espelho causando ofuscamento durante o teste;</li>
      <li>Cuidado com a presença de reflexo de lâmpadas ou lustres de teto que reflitam no espelho, que também poderão atrapalhar o teste;</li>
      <li>Evitar qualquer obstáculo visual no trajeto dos olhos do cliente até a tabela durante o teste, como o trânsito excessivo de pessoas;</li>
      <li>Certifique-se que o ambiente está tranquilo e viável para realização do teste.</li>
    </OL>

  </Typography>
)

export default PosicionandoText