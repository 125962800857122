import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import letras from 'assets/img/tipos/letras.png'
import es from 'assets/img/tipos/es.png'
import numeros from 'assets/img/tipos/numeros.png'
import simbolos from 'assets/img/tipos/simbolos.png'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: 20px 0;
`

const Title = styled.div`
  text-align: center;
  font-weight: 500px;
  font-size: 18px;
  `

const Img = styled.img`
  flex: 0 0 auto;
  width: 220px;
  height: auto;
  margin: 10px;
  border-width: 4px;
  border-style: solid;
`

const Item = styled.div`
  flex: 0 0 auto;
  cursor: pointer;

  ${Title} {
    color: ${props => props.selected ? '#222' : '#aaa'};
  }

  ${Img} {
    border-color: ${props => props.selected ? props.theme.colors.primary : '#ddd'};
  }
`

const items = [
  {
    id: 'letters',
    text: 'Letras',
    img: letras
  },
  {
    id: 'letter_e',
    text: 'Letras E',
    img: es
  },
  {
    id: 'numbers',
    text: 'Números',
    img: numeros
  },
  {
    id: 'symbols',
    text: 'Símbolos',
    img: simbolos
  },
]

const SelectBox = props => {
  const [selected, setSelected] = useState('letters')

  useEffect(() => {
    props.onChange(selected)
  }, [props, selected])

  return (
    <Wrapper>
      {items.map(item => (
        <Item id={item.id} selected={selected === item.id} onClick={() => setSelected(item.id)} >
          <Title>{item.text}</Title>
          <Img src={item.img} />  
        </Item>
      ))}
    </Wrapper>
  )
}
export default SelectBox