export default {
	breakpoint: 700,
	bannerWidth: 1224,
	headerHeight: 54,
	headerBgColor: 'indigo',
	containerWidth: 1024,
	colors: {
		primary: '#334bff'
	},
	spine: {
		breakpoints: {
			sm: 0,
			md: 700
		}
	}
}