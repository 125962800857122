import React from 'react'
import { Typography, H2, H3, P, Img } from 'components/Typography'

import img5 from 'assets/img/conheca/img5.jpg'
import img6 from 'assets/img/conheca/img6.jpg'
import img7 from 'assets/img/conheca/img7.jpg'
import img8 from 'assets/img/conheca/img8.jpg'
import img9 from 'assets/img/conheca/img9.jpg'
import img10 from 'assets/img/conheca/img10.jpg'
import img11 from 'assets/img/conheca/img11.jpg'

const TestandoText = () => (
  <Typography>
    <H2>Realizando o Teste de Visão</H2>

    <P>Após observadas as condições exigidas, explicado ao cliente o objetivo do teste e orientado como realizá-lo, pode-se ter três tipos de teste quanto à faixa etária e escolaridade do cliente:</P>




    <H3>No Adulto Alfabetizado</H3>

    <P>1. O cliente se posiciona confortavelmente na cadeira e encosta no suporte da cabeça com os olhos nivelados horizontalmente com o espelho (Figura 5);</P>

    <Img src={img5} title="Figura 5" caption="Posicionamento do cliente adulto." />

    <P>2. Se o cliente for usuário de óculos (ou lentes de contato), deve mantê-los no rosto durante o teste;</P>

    <P>3. Oclui primeiramente o olho esquerdo com a palma da mão esquerda ou um oclusor e ver qual a menor linha de letras que consegue identificar (Figura 6).</P>

    <P><b>Cuidado!</b> Na oclusão com a própria palma da mão do cliente, deve-se observar se ele não faz a leitura das letras utilizando o olho ocluído vendo por entre os dedos e falseando o teste. Logo, a palma da mão deve estar bem à frente do olho ocluído para evitar tal erro;</P>
    
    <Img src={img6} title="Figura 6" caption="Testagem do olho direito." />

    <P>4. Em seguida, oclui o olho direito com a palma da mão direita ou um oclusor e ver qual a menor linha de letras que consegue identificar (Figura 7);</P>
    
    <Img src={img7} title="Figura 7" caption="Testagem do olho esquerdo." />

    <P>5. Anota-se o resultado.</P>




    <H3>Na Criança Pré-Escolar</H3>

    <P>1. Posiciona-se o cliente confortavelmente na cadeira com um assento elevatório e encosta sua cabeça no suporte próprio com os olhos nivelados horizontalmente com o espelho (Figura 8);</P>

    <Img src={img8} title="Figura 8" caption="Posicionamento da criança." />

    <P>2. Se o cliente for usuário de óculos (ou lentes de contato), deve mantê-los no rosto durante o teste;</P>

    <P>3. Em sendo pré-escolar, as tabelas a serem utilizadas serão ou a contendo figuras ou a da letra “E” em posições diferentes (Tabelas à direita da Figura 2);</P>

    <P>4. Ao se utilizar a tabela com a letra “E”, deve-se pedir que o menor utilize seus dedos em conjunto para indicar a posição de cada “E”, seja para cima, para baixo, para a direita ou para a esquerda (Figura 9);</P>

    <Img src={img9} title="Figura 9" caption="Orientações para o teste com a tabela com a letra “E”." />

    <P>5. Como o olho esquerdo ocluído, seja com a palma da mão esquerda ou um oclusor, um auxiliar (acompanhante responsável ou o próprio profissional) aponta na tabela com o indicador abaixo de cada letra “E” ou figura a ser identificada (Figura 10).</P>
      
    <P><b>Cuidado!</b> Na oclusão com a própria palma da mão do cliente, deve-se observar se ele não faz a leitura das letras utilizando o olho ocluído vendo por entre os dedos e falseando o teste. Logo, a palma da mão deve estar bem à frente do olho ocluído para evitar tal erro;</P>

    <Img src={img10} title="Figura 10" caption="Testagem do olho direito utilizando a tabela com letras “E”." />

    <P>6. O mesmo procedimento é realizado no olho direito (Figura 11);</P>

    <Img src={img11} title="Figura 11" caption="Testagem do olho esquerdo utilizando a tabela com letras “E”." />

    <P>7. Anota-se o resultado.</P>



    <H3>No Adulto Não Alfabetizado</H3>
    <P>1. O cliente se posiciona confortavelmente na cadeira e encosta no suporte da cabeça com os olhos nivelados horizontalmente com o espelho (Figura 5);</P>
    <P>2. Se o cliente for usuário de óculos (ou lentes de contato), deve mantê-los no rosto durante o teste;</P>
    <P>3. Em sendo não alfabetizado, as tabelas a serem utilizadas serão ou a contendo figuras ou a da letra “E” em posições diferentes. Se o cliente conhecer números, a tabela numérica poderá também ser utilizada (Figura 2);</P>
    <P>4. Ao se utilizar a tabela com a letra “E”, deve-se pedir que o cliente utilize seus dedos em conjunto para indicar a posição de cada “E”, seja para cima, para baixo, para a direita ou para a esquerda (Figura 9);</P>
    <P>5. O cliente oclui primeiramente o olho esquerdo e um auxiliar aponta na tabela com o indicador abaixo de cada letra “E” ou figura a ser identificada (Figura 10). Cuidado! Na oclusão com a própria palma da mão do cliente, deve-se observar se ele não faz a leitura das letras utilizando o olho ocluído vendo por entre os dedos e falseando o teste. Logo, a palma da mão deve estar bem à frente do olho ocluído para evitar tal erro;</P>
    <P>6. O mesmo procedimento é realizado no olho direito (Figura 11);</P>
    <P>7. Anota-se o resultado.</P>

  </Typography>
)

export default TestandoText