import React from 'react'
import { Route, NavLink } from 'react-router-dom'
import Top from 'components/Top'
import Container from 'components/Container'
import { Wrapper, ToC, Content } from 'components/Tutoriais'

import IntroText from './pages/intro'
import TabelasText from './pages/tabelas'

const PassosPage = () => (
  <div>
    <Top>
      <Container>
        <Top.Title>Passo-a-Passo</Top.Title>
        <Top.SubTitle>Saiba como utilizar</Top.SubTitle>
      </Container>
    </Top>
    <Container>
      <Wrapper>
        <ToC>
          <ul>
            <li><NavLink to="/passos/" exact>Introdução</NavLink></li>
            <li><NavLink to="/passos/tabelas">Imprimindo as Tabelas</NavLink></li>
            <li><NavLink to="/passos/a">Afixando a Tabela</NavLink></li>
            <li><NavLink to="/passos/a">Condições do Ambiente para o Exame</NavLink></li>
            <li><NavLink to="/passos/a">Realizando o Teste de Visão</NavLink></li>
            <li><NavLink to="/passos/a">Manutenção da Tabela</NavLink></li>
            <li><NavLink to="/passos/a">Como Analiso o Resultado</NavLink></li>
            <li><NavLink to="/passos/a">O que eu faço com o Resultado do Teste</NavLink></li>
          </ul>
        </ToC>
        <Content>
          <Route path="/passos" component={IntroText} exact />
          <Route path="/passos/tabelas" component={TabelasText} exact />
        </Content>
      </Wrapper>
    </Container>
  </div>
)

export default PassosPage