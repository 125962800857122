import React, { useState, useLayoutEffect } from 'react'
import { Wrapper, HeaderContainer, Top, Bottom, Logo } from './styles'
import Burger from './Burger'
import Menu from './Menu'

const Header = () => {
	const [pos, setPos] = useState(null)
	const [hidden, setHidden] = useState(false)
	const [scrolled, setScrolled] = useState(false)
	const [collapsed, setCollapsed] = useState(true)

	useLayoutEffect(() => {
		const handleScroll = () => {
			setHidden(window.pageYOffset > pos && window.pageYOffset >= 200)
			setScrolled(window.pageYOffset > 10)
			setPos(window.pageYOffset)
		}

		window.addEventListener('scroll', handleScroll)
		return () => window.removeEventListener('scroll', handleScroll)
	}, [pos])

	return (
		<Wrapper className={`${hidden ? 'hidden' : 'active'} ${scrolled && 'scrolled'} ${collapsed && 'collapsed'}`}>
			<HeaderContainer>
				<Top collapsed={collapsed}>
					<Logo>VHScreening</Logo>
					<Burger onClick={() => setCollapsed(!collapsed)} active={!collapsed} />
				</Top>
				<Bottom>
					<Menu onMenuClick={() => setCollapsed(true)} className={`${scrolled && 'scrolled'} ${collapsed && 'collapsed'}`} />
				</Bottom>
			</HeaderContainer>
		</Wrapper>
	);
}

export default Header