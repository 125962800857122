import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import 'font-awesome/css/font-awesome.min.css';
import './style.css'

import Top from 'components/Top'
import Container from 'components/Container'
import Button from 'components/Button'
import SelectBox from 'components/Form/SelectBox'
import { Form, FormGroup, Label, Input } from 'components/Form'

import { calculate, fontTypes } from './helpers'
import calcImg from 'assets/img/calculadora.jpg'

const CalculadoraPage = () => {
  const [dist1, setDist1] = useState(200)
  const [dist2, setDist2] = useState(500)
  const [optotipo, setOptotipo] = useState(null)
  const [fontType, setFontType] = useState('letters')

  useEffect(() => {
    setOptotipo(calculate(dist1, dist2))
  }, [dist1, dist2])

  const handlePrint = e => {
    e.preventDefault()
    window.print()
  }
  
  return (
    <div>
      <Top>
        <Container>
          <Top.Title>Calculadora</Top.Title>
          <Top.SubTitle>Utilize o Vision Hair Screening aqui</Top.SubTitle>
        </Container>
      </Top>

      <div className="wrapper">
        <div className="not-printable">
          <Text>
            Para gerar um teste para sua barbearia é fácil. Você só irá precisar de duas medidas: (1) A distância dos olhos do cliente ao espelho e (2) a distância do espelho a parede oposta, como indicado na imagem.
          </Text>
          <Text>
            Após ter informado as medidas (1) e (2), escolha qual tipo de tabela você prefere. Após escolhido, agora é só clicar no botão Imprimir! A folha deverá ser de tamanho A4 e na orientação "paisagem".
          </Text>
          <Image src={calcImg} />
          <Form>
            <FormGroup>
              <Label htmlFor="dist_1">1. Distância dos olhos ao espelho (centímetros)</Label>
              <Input
                type="text"
                id="dist_1"
                value={dist1}
                onChange={(e) => setDist1(e.target.value)}
                />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="dist_1">2. Distância do espelho a parede oposta (centímetros)</Label>
              <Input
                type="text"
                id="dist_2"
                value={dist2}
                onChange={(e) => setDist2(e.target.value)}
                />
            </FormGroup>
            <FormGroup>
              <Label>3. Tipo de Tabela</Label>
              <SelectBox onChange={(value) => setFontType(value)} />
            </FormGroup>
            <FormGroup>
              <Label>4. Imprima em folha A4 em orientação paisagem.</Label>
            </FormGroup>
            <Button style={{margin: '0 auto', marginBottom: 10}} onClick={handlePrint}>Imprimir</Button>
          </Form>
        </div>

        <div className="document" style={{fontSize: `${optotipo}mm`}}>
          <div className="container">

            {Object.keys(fontTypes[fontType]).map(line => (
              <div className={`line ${line}`}>
                {fontTypes[fontType][line].map(l => {
                  const letter = l
                  const orient = l[1] || '0'

                  // if (fontType === 'symbols') {
                  //   return (
                  //     <div className={`letter letter-${orient}`}>
                  //       <i className={`icon`}>{letter}</i>
                  //     </div>
                  //   )
                  // }

                  if (fontType === 'symbols') {
                    return (
                      <div className={`letter symbol`}>{letter[0]}</div>
                    )
                  }

                  return (
                    <div className={`letter letter-${orient}`}>{letter[0]}</div>
                  )
                })}
              </div>
            ))}

          </div>
        </div>

      </div>
    </div>
  )
}

const Text = styled.p`
  font-size: 18px;
  color: #333;
  text-align: justify;
  width: 100%;
  padding: 0 10px;
  box-sizing: border-box;
`

const Image = styled.img`
  width: 100%;
`

export default CalculadoraPage