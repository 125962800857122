import React from 'react'
import styled from 'styled-components'
import Top from 'components/Top'
import Container from 'components/Container'

const EticaPage = () => (
  <div>
    <Top>
      <Container>
        <Top.Title>Ética</Top.Title>
        <Top.SubTitle>O que eu faço com o resultado do teste?</Top.SubTitle>
      </Container>
    </Top>
    <Container style={{maxWidth: 700}}>
        <Text>
          O teste é uma livre iniciativa por parte do cliente. O teste tem por finalidade realizar uma triagem rápida e frequente na população para detectar alguma baixa da acuidade visual, seja por falta de “graus” dos óculos ou outras anormalidades. O teste não indica nenhum diagnóstico e um resultado favorável que apresente uma boa visão durante o teste não exclui, de forma nenhuma, consultas periódicas ao oftalmologista, pois doenças como o glaucoma, ceratocone ou retinopatia diabética periférica podem se instalar, inicialmente, de forma assintomática e com visão boa.
        </Text>
    </Container>
  </div>
)

const Text = styled.p`
  font-size: 20px;
  color: #333;
  line-height: 1.5;
  text-align: justify;
  margin: 2em 0;
`

export default EticaPage