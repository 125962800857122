import styled from 'styled-components'

export const Form = styled.form`
  
`

export const Input = styled.input`
  max-width: 200px;
  display: block;
  width: 100%;
  box-sizing: border-box;
  margin: .8em auto;
  padding: .6em .6em;
  font-size: 18px;
  color: #333;
  border: 1px solid #555;
  border-radius: 4px;
  text-align: center;
  outline: none;

  &:focus {
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.2);
  }
`

export const Label = styled.label`
  display: block;
  width: 100%;
  font-size: 18px;
  font-weight: 500;
  color: #555;
`

export const FormGroup = styled.div`
  margin: 30px 10px;
`