import React from 'react'
import { Typography, H2, P, Img } from 'components/Typography'

import img13 from 'assets/img/conheca/img14.jpg'
import img14 from 'assets/img/conheca/img13.jpg'

const AnaliseText = () => (
  <Typography>
    <H2>Manutenção das Tabelas</H2>
    <P>O teste é dito normal se com cada olho isoladamente o cliente for capaz de ver todas as letras da última linha da tabela utilizada, seja de letras, números, letras “E” ou figuras (símbolos) ou metade das letras mais uma da última linha (sequenciais ou não). Por exemplo, 5 letras de um total de 8 na linha. (Figura 13 e 14). A pessoa que não apresentar visão normal durante o teste deverá repetir o exame para confirmação.</P>

    <Img src={img13} title="Figura 13" caption="Resultado considerado dentro da normalidade. Todos os optotipos identificados na última linha (faixa verde)." />

    <Img src={img14} title="Figura 14" caption="Resultado considerado dentro da normalidade. Metade dos optotipos mais 1 identificados de forma contínua ou não (faixas laranjas)." />
  </Typography>
)

export default AnaliseText