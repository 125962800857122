import styled from 'styled-components'
import bg from 'assets/img/banner-bg-gray.jpg'

const Top = styled.div`
  width: 100%;
  padding: ${props => props.theme.headerHeight + 10}px 0px 10px 0px;
  height: auto;
  background: rgb(224,228,237);
  background: linear-gradient(125deg, rgba(228,234,240,1) 15%, rgba(205,208,220,1) 85%);
  /* background: rgb(143,198,255);
  background: linear-gradient(338deg, rgba(143,198,255,1) 0%, rgba(111,186,255,1) 35%, rgba(0,146,255,1) 100%); */
  /* background-image: url(${bg}); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`

const Title = styled.div`
  color: #000000cc;
  font-size: 32px;
  font-weight: 500;
  text-align: center;
  margin: .5em 0 .2em 0;
`

const SubTitle = styled.div`
  color: #00000088;
  font-size: 20px;
  font-weight: 400;
  text-align: center;
  margin: 0 0 1em 0;
`

Top.Title = Title
Top.SubTitle = SubTitle

export default Top