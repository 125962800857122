import React from 'react'
import { Typography, H2, P, Img } from 'components/Typography'

import img1 from 'assets/img/conheca/img1.jpg'
import img2 from 'assets/img/conheca/img2.jpg'
const ImprimindoText = () => (
  <Typography>
    <H2>Imprimindo as Tabelas</H2>
    <P>Para realizar o exame é necessária a impressão das tabelas por meio do site próprio do Vision Hair Screening (www.vhscreening.com). Inicialmente, mede-se com uma fita métrica ou trena a distância, em centímetros, que vai da região entre os olhos de um voluntário sentado na cadeira do barbeiro e encostado no apoio de cabeça até o espelho situado a sua frente. Em seguida, mede-se a distância entre o mesmo espelho e a parede oposta atrás da cadeira. Esses valores são inseridos nas respectivas caixas da calculadora do site (Figura 1).</P>

    <Img src={img1} title="Figura 1" caption="Realizando as medidas." />

    <P>Após a inserção dos valores encontrados, clica-se na tecla imprimir para que as tabelas com letras, números, figuras ou “E” em diferentes posições sejam impressas em uma folha de papel A4 branco padrão na cor preta. Cheque se a impressão da sua impressora nessa cor está em condições adequadas. Todos os caracteres em cada tabela serão ajustados automaticamente para o tamanho de acordo com cada distância inserida pelo software (Figura 2)</P>
    
    <Img src={img2} title="Figura 2" caption="Tabelas com letras, números, letras “E” e símbolos (figuras)." />
  </Typography>
)

export default ImprimindoText