import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  box-sizing: border-box;
  padding: 0 10px;

  @media screen and (max-width: ${props => props.theme.breakpoint}px) {
    flex-direction: column;
    padding: 0 20px;
  }
`

export const ToC = styled.div`
  position: sticky;
  top: ${props => props.theme.headerHeight + 20}px;
  flex: 0 0 auto;
  width: 100%;
  max-width: 250px;
  font-size: 14px;
  color: #555;
  margin: 20px auto;
  padding: 10px;
  box-sizing: border-box;
  background: #f5f5f5;
  border-radius: 8px;
  box-sizing: border-box;
  font-family: inherit;

  ul, ol {
    margin: 0;
    padding: .2em .8em;
    list-style: none;

    li {
      margin: .75em 0;
      padding: 0;

      a {
        text-decoration: none;
        color: #555;
        font-size: 15px;

        &:hover {
          text-decoration: underline;
        }

        &.active {
          font-weight: bold;
        }
      }
    }
  }

  @media screen and (max-width: ${props => props.theme.breakpoint}px) {
    max-width: 250px;
    position: static;
    top: 0;
  }
`

export const Content = styled.div`
  padding: 1em 1.4em;
  box-sizing: border-box;

  @media screen and (max-width: ${props => props.theme.breakpoint}px) {
    padding: 0 0em;
  }
`