import React from 'react'
import styled from 'styled-components'

export const Steps = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`

const Wrapper = styled.div`
  width: 100%;
  max-width: 250px;
  margin: 20px;
  display: flex;
  flex-direction: column;
  text-align: center;
  border: 0px solid red;
`

const Title = styled.p`
  display: block;
  font-size: 18px;
  font-weight: 500;
  margin: .5em 0;
  color: #404044;
`

/* const Text = styled.p`
  font-size: 16px;
  font-weight: 300;
  display: block;
  margin: 0;
  color: #8b8b92;
  text-align: center;
` */

const Img = styled.img`
  display: block;
  width: 190px;
  height: 190px;
  border-radius: 50%;
  margin: 10px auto;
`

export const Step = ({ title, text, img }) => (
  <Wrapper>
    <Img src={img} />
    <Title>{title}</Title>
    {/* <Text>{text}</Text> */}
  </Wrapper>
)
