import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { Helmet } from 'react-helmet'
import Routes from 'routes'
import GlobalStyle from 'styles/global'
import theme from 'styles/theme'
import Wrapper from 'components/Wrapper'
import Main from 'components/Main'
import Header from 'components/Header'
import Footer from 'components/Footer'

const App = () => {
	return (
		<ThemeProvider theme={theme}>
			<Helmet>
				<title>Vision Hair Screening</title>
				<meta name="title" content="Vision Hair Screening"/>
				<meta name="description" content="Beleza e bem estar aliados à prevenção da cegueira"/>
			</Helmet>
			<GlobalStyle/>
				<BrowserRouter>
					<Wrapper>
						<Header/>
						<Main>
							<Routes/>
						</Main>
						<Footer/>
					</Wrapper>
				</BrowserRouter>
		</ThemeProvider>
	);
}

export default App;
