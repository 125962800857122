export const calculate = (dist1, dist2) => {
  dist1 = parseFloat(dist1)
  dist2 = parseFloat(dist2)
  let size = 0.00145444206*(dist1 + dist2)*10
  size = Math.min(40, size)
  size = Math.max(2, size)
  console.log(size)
  return size
}

export const fontTypes = {
  letters: {
    line1: ['P', 'E', 'C', 'F', 'D'],
    line2: ['E', 'D', 'F', 'C', 'Z'],
    line3: ['F', 'E', 'L', 'O', 'P'],
    line4: ['D', 'E', 'F', 'P', 'O'],
  },
  letter_e: {
    line1: ['E1', 'E0', 'E3', 'E2', 'E1'],
    line2: ['E2', 'E2', 'E1', 'E0', 'E3'],
    line3: ['E2', 'E3', 'E0', 'E1', 'E2'],
    line4: ['E0', 'E3', 'E0', 'E1', 'E0'],
  },
  numbers: {
    line1: ['5', '7', '1', '3', '4'],
    line2: ['7', '1', '8', '6', '9'],
    line3: ['4', '2', '8', '1', '5'],
    line4: ['2', '5', '8', '1', '4'],
  },
  symbols: {
    line1: ['A', 'B', 'C', 'B', 'D'],
    line2: ['B', 'D', 'B', 'C', 'B'],
    line3: ['B', 'B', 'C', 'D', 'A'],
    line4: ['D', 'B', 'B', 'A', 'C'],
  }
}