import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { RedirectRoute } from './helpers'

// Pages
import HomePage from 'pages/HomePage'
import PassosPage from 'pages/PassosPage'
import ConhecaPage from 'pages/ConhecaPage'
import EticaPage from 'pages/EticaPage'
import CalculadoraPage from 'pages/CalculadoraPage'
import NotFoundPage from 'pages/NotFoundPage'

const Routes = () => {
  return (
    <Switch>
			<Route path="/" component={HomePage} exact />
			<Route path="/passos" component={PassosPage} />
			<Route path="/conheca" component={ConhecaPage} />
			<Route path="/etica" component={EticaPage} />
			<Route path="/calculadora" component={CalculadoraPage} exact />
			<RedirectRoute path="/teste" url="https://docs.google.com/forms/d/e/1FAIpQLSfLSpJlWeSZ2IZewHi-ZOVW7QMdaA2vdrTALKbTK-dy4bcsPQ/viewform?usp=sf_link" />
			<Route component={NotFoundPage} />
    </Switch>
  );
}

export default Routes