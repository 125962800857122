import React from 'react'
import Container from 'components/Container'
import { Steps, Step } from 'components/Steps'

import i1 from 'assets/img/info/i1.png'
import i2 from 'assets/img/info/i2.png'
import i3 from 'assets/img/info/i3.png'
import i4 from 'assets/img/info/i4.png'
import i5 from 'assets/img/info/i5.png'
import i6 from 'assets/img/info/i6.png'

const ComoFuncionaSection = () => (
  <Container>
    <Steps>
      <Step
        title="1. Entre no site"
        text="Um breve texto explicando melhor o passo"
        img={i1}
      />
      <Step
        title="2. Faça as medidas"
        text="Um breve texto explicando melhor o passo"
        img={i2}
      />
      <Step
        title="3. Imprima as tabelas"
        text="Um breve texto explicando melhor o passo"
        img={i3}
      />
      <Step
        title="4. Fixe a tabela"
        text="Um breve texto explicando melhor o passo"
        img={i4}
      />
      <Step
        title="5. Posicione o paciente"
        text="Um breve texto explicando melhor o passo"
        img={i5}
      />
      <Step
        title="6. Realize o teste"
        text="Um breve texto explicando melhor o passo"
        img={i6}
      />
    </Steps>
  </Container>
)

export default ComoFuncionaSection