import styled from 'styled-components'

const Section = styled.section`
  width: 100%;
  height: auto;
  min-height: ${props => props.full ? `calc(100vh - ${props.theme.headerHeight}px)` : 'auto'};
  /* background: ${props => props.theme.colors.primary}; */
  /* display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center; */
  background-image: ${props => `url(${props.bg})` || 'none'};
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: ${props => props.pushTop ? props.theme.headerHeight : 0}px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Header = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0;
`

const Content = styled.div`
  margin: 20px auto;
`

const Title = styled.h2`
  display: block;
  max-width: 500px;
  display: inline-block;
  padding-bottom: .3em;
  margin: .3em auto;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.8);
  font-size: 32px;
  text-align: center;
  border-bottom: 3px solid rgba(0, 0, 0, 0.5);
`

const SubTitle = styled.h3`
  display: block;
  max-width: 500px;
  display: inline-block;
  margin: .0 auto;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.5);
  font-size: 22px;
  text-align: center;
`

Section.Header = Header
Section.Content = Content
Section.Title = Title
Section.SubTitle = SubTitle

export default Section