import React from 'react'
import styled from 'styled-components'

const Footer = () => {
	return (
		<Wrapper className="not-printable">
			<b>Vision Hair Screening - 2020</b>
		</Wrapper>
	);
}

const Wrapper = styled.footer`
	margin-top: 50px;
	padding: 2em;
	background: #eee;
	color: #555;
	text-align: center;
`

export default Footer