import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
	html, body {
		margin: 0;
		padding: 0;
		font-family: 'Roboto', sans-serif; /* Work Sans */
		width: 100%;
		height: 100%;
	}

	div {
  	box-sizing: border-box;
	}

	/* body {
		padding-top: ${props => props.theme.headerHeight}px;
	} */
`;

export default GlobalStyle