import React from 'react'
import { Typography, H2, P } from 'components/Typography'

const IntroText = () => (
  <Typography>
    <H2>O que é</H2>
    <P>O VHS é um método simples para testar a visão de clientes em salões de beleza, barbearias e ambientes correlatos utilizando a reflexão de espelho.</P>

    <H2>Como funciona</H2>
    <P>Durante a realização do procedimento estético na cadeira do barbeiro ou cabeleireiro, o cliente testa a visão de cada olho de forma individual utilizando o espelho que está na sua frente. O espelho reflete uma tabela com letras, números, figuras ou “E” em posições diferentes (optotipos), a depender da faixa etária e escolaridade do cliente. A tabela é afixada na parede oposta a que o espelho está afixado em uma altura um pouco acima da cabeça do paciente para permitir sua visualização.</P>
  </Typography>
)

export default IntroText