import React from 'react'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import { Link } from 'react-scroll'

const Menu = props => {
	return (
		<Nav>
			<Item onClick={() => props.onMenuClick()} scrollTo="banner" to="/" type="scroll">Home</Item>
			{/* <Item onClick={() => props.onMenuClick()} to="/passos" type="link">Passo-a-Passo</Item> */}
			<Item onClick={() => props.onMenuClick()} to="/conheca" type="link">Conheça Melhor</Item>
			<Item onClick={() => props.onMenuClick()} to="/etica" type="link">Ética</Item>
			<Item onClick={() => props.onMenuClick()} to="/calculadora" type="link">Calculadora</Item>
		</Nav>
	);
}

const RawItem = ({ type, children, scrollTo, to, ...props }) => {
	const location = useLocation()

	return (
		type === 'scroll' && location.pathname === '/' ?
			<Link
			to={scrollTo}
			duration={500}
			smooth={true}
			offset={-55}
			{...props}>
				{children}
			</Link>
		:
			<NavLink
			to={to}
			{...props}>
				{children}
			</NavLink>
		)
}

const Nav = styled.nav`
	display: flex;
	flex-direction: row;
	/* justify-content: center; */
	margin: 0;
	list-style: none;
	box-sizing: border-box;

	@media screen and (max-width: ${props => props.theme.breakpoint}px) {
		width: 100%;
		flex-direction: column;
		padding: 1em 0 3em 0;
		text-align: left;
	}
`

const Item = styled(RawItem)`
	display: block;
	margin: 0 .7em;
	padding: 0.2em .0em;
	display: block;
	color: #333;
	opacity: 0.8;
	font-family: inherit;
	font-size: 14px;
	font-weight: 600;
	border-bottom: 2px solid transparent;
	text-decoration: none;
	text-transform: uppercase;
	text-rendering: optimizeLegibility;
	letter-spacing: 0.8px;
	transition: .2s ease all;
	user-select: none;
	cursor: pointer;

	&:hover {
		opacity: 1;
		border-color: #555;
	}

	@media screen and (max-width: ${props => props.theme.breakpoint}px) {
		font-size: 18px;
		padding: .5em .8em;
		color: #555;
  }
`

export default Menu