import React from 'react'
import { Typography, H2, P } from 'components/Typography'

const MedindoText = () => (
  <Typography>
    <H2>Imprimindo as Tabelas</H2>
    <P>Para realizar o exame é necessária a impressão das tabelas por meio do site próprio do Vision Hair Screening (VHS). Inicialmente, mede-se com uma fita métrica ou trena a distância, em metros, que vai da região entre os olhos do cliente sentado na cadeira do barbeiro e encostado no apoio de cabeça até o espelho situado a sua frente. Em seguida, mede-se a distância entre o mesmo espelho e a parede oposta atrás da cadeira. Esses valores são inseridos nas respectivas caixas da calculadora do site (Figura 1).</P>
  </Typography>
)

export default MedindoText