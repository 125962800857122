import React from 'react'
import styled from 'styled-components'
import Container from 'components/Container'

import img1 from 'assets/img/apresentacao/hair.jpg'
import img2 from 'assets/img/apresentacao/teste.png'
import img3 from 'assets/img/apresentacao/fT.png'

const ComoFuncionaSection = () => (
  <Container>

    <Wrapper>
      <Image src={img1} />
      <Text>
        Onde ficamos frequentemente sentados à espera de um serviço de forma ociosa? Pois é! Será que existe um ambiente que possamos utilizar esse tempo perdido em prol da saúde? Foi fazendo essa pergunta que criamos o VISION HAIR SCREENING, um teste de visão que utiliza o reflexo do espelho de salões de beleza e barbearias.
      </Text>
    </Wrapper>

    <Wrapper>
      <Text>
        Em 2010, 2 bilhões de pessoas no mundo necessitavam de óculos para ter uma boa visão. Isso correspondia a 28,3% da população. Em 2050, a estimativa é de que 4,8 bilhões de pessoas terão algum tipo de deficiência visual que lhe vai exigir o uso de óculos.
      </Text>
      <Image src={img2} />
    </Wrapper>

    <Wrapper>
      <Image src={img3} size="contain" />
      <Text>
        Nossa missão é popularizar a detecção dos problemas visuais por meio de um teste simples, rápido e prático em crianças e adultos que frequentam salões de beleza e barbearias, estimulando-os a procurar atendimento especializado com um médico oftalmologista.
      </Text>
    </Wrapper>
  </Container>
)

const Text = styled.div`
  flex: 1 1 auto;
  max-width: 500px;
  display: block;
  font-size: 22px;
  margin: 0 1.6em;
  line-height: 1.7;
  color: #222;
  text-align: justify;
`

const Image = styled.div`
  flex: 0 0 auto;
  width: 400px;
  height: 400px;
  margin: 10px;
  background: #ddd;
  border-radius: 50%;
  margin: 0 auto;
  background: url(${props => props.src});
  background-position: center;
  background-repeat: no-repeat;
  background-size: ${props => props.size ? props.size : 'cover'};

  @media screen and (max-width: ${props => props.theme.breakpoint}px) {
    width: 340px;
    height: 340px;
  }
`

const Wrapper = styled.div`
  margin: 50px 0;
  padding: 20px 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: ${props => props.theme.breakpoint}px) {
    flex-direction: column;
    margin: 0px 0;

    ${Text} {
      margin: 2em;
    }

    :nth-child(2) ${Text} {
      order: 1;
    }
  }
`

export default ComoFuncionaSection