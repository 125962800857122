import styled from 'styled-components'

const Button = styled.button`
	display: block;
	background: ${props => props.outline ? 'transparent' : props.theme.colors.primary};
	opacity: 1;
	color: ${props => props.outline ? props.theme.colors.primary : 'white'};
	border: 2px solid ${props => props.outline ? props.theme.colors.primary : 'transparent'};
	padding: .6em 1em;
	font-size: 15px;
	border-radius: 4px;
	font-family: inherit;
	font-weight: 600;
	outline: none;
	text-decoration: none;
	text-transform: uppercase;
	cursor: pointer;

	&:hover {
		filter: brightness(110%)
	}
	
	&:active {
		filter: brightness(90%)
	}
`;

const Group = styled.div`
	margin: .5em 0;
	display: flex;
	justify-content: center;

	${Button} {
		margin-right: .8em;

		&:last-child {
			margin-right: 0;
		}
	}
`;

Button.Group = Group

export default Button