import React from 'react'
import { Typography, H2, P, Img } from 'components/Typography'

import img3 from 'assets/img/conheca/img3.jpg'
import img4 from 'assets/img/conheca/img4.jpg'

const FixandoText = () => (
  <Typography>
    <H2>Afixando a Tabela</H2>
    <P>Tendo em mãos as 4 tabelas impressas, recomenda-se que a tabela a ser utilizada seja fixada em uma posição que esteja um pouco acima do suporte da cabeça da cadeira do barbeiro para que não seja obstruída pela cabeça do cliente (Figura 3).</P>

    <Img src={img3} title="Figura 3" caption="Altura ideal de fixação da tabela." />

    <P>As tabelas devem ser utilizadas isoladamente seja por fixação individual com fita na parede ou por meio de suporte de acrílico ou moldura que permita a troca das tabelas (Figura 4).</P>

    <Img src={img4} title="Figura 4" caption="Métodos de fixação das tabelas: fita adesiva (à esquerda) e moldura de acrílico (à direita)." />

  </Typography>
)

export default FixandoText