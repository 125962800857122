import React from 'react'
import styled from 'styled-components'
import Button from 'components/Button'
import logo from 'assets/img/logo.png'

const BannerSection = () => (
  <Wrapper>
    <Container>
      <Img src={logo} />
      <Text>Beleza e bem estar aliados à prevenção da cegueira</Text>
      <Button.Group>
        <Button as="a" href="/conheca" outline>Conheça melhor</Button>
        <Button as="a" href="/calculadora">Usar calculadora</Button>
      </Button.Group>
    </Container>
  </Wrapper>
)

const Wrapper = styled.div`
  width: 100%;
  padding: ${props => props.theme.headerHeight + 10}px 0px 10px 0px;
  height: auto;
  background: rgb(224,228,237);
  background: linear-gradient(125deg, rgba(228,234,240,1) 15%, rgba(205,208,220,1) 85%);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`

const Container = styled.div`
  width: 100%;
	max-width: ${props => props.theme.bannerWidth}px;
  margin: 0 auto;
  padding: min(5vw, 70px) 10px;
  text-align: center;
`

const Text = styled.p`
  margin: 1.2em 0;
  font-size: 26px;
  color: #222;
  font-weight: 500;
  text-align: inherit;
`

const Img = styled.img`
  margin: 0;
  padding: 0;
  width: 100%;
  max-width: 800px;
`

export default BannerSection