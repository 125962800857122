import React from 'react'
import { Typography, H2, P, Img } from 'components/Typography'

import img12 from 'assets/img/conheca/img12.jpg'

const ManutencaoText = () => (
  <Typography>
    <H2>Manutenção das Tabelas</H2>
    <P>Por serem impressas em papel ou material que possa sofrer danos com o tempo, as tabelas devem ser constantemente avaliadas quanto à necessidade de sua troca (Figura 12).</P>

    <Img src={img12} title="Figura 12" caption="Troca de tabela em suporte de acrílico." />
  </Typography>
)

export default ManutencaoText