import React from 'react'
import Page from 'components/Page'
import Section from 'components/Section'

import BannerSection from './sections/BannerSection'
import ApresentacaoSection from './sections/ApresentacaoSection'
import ComoFuncionaSection from './sections/ComoFuncionaSection'
import QuemSomosSection from './sections/QuemSomosSection'

import bg from 'assets/img/banner-bg-gray.jpg'

const HomePage = () => {
	return (
		<Page>
			<Section id="banner">
				<BannerSection/>
			</Section>

			<Section bg={bg}>
				{/* <Section.Title>Apresentação</Section.Title> */}
				<ApresentacaoSection/>
			</Section>
			
			<Section id="como-funciona" style={{background: 'whitesmoke', padding: '30px 0 80px 0'}}>
				<Section.Header>
					<Section.Title>Como Funciona</Section.Title>
					<Section.SubTitle>Saiba o passo-a-passo</Section.SubTitle>
				</Section.Header>
				<Section.Content>
					<ComoFuncionaSection/>
				</Section.Content>
			</Section>
			
			<Section id="quem-somos" style={{background: 'white', padding: '30px 0 80px 0'}}>
				<Section.Header>
					<Section.Title>Nosso Time</Section.Title>
					<Section.SubTitle>Conheça quem está por trás do projeto</Section.SubTitle>
				</Section.Header>
				<Section.Content>
					<QuemSomosSection/>
				</Section.Content>
			</Section>
			
			{/* <Section full id="contato" style={{background: '#f0f0f0'}}>
				<Section.Title>Contato</Section.Title>
				
			</Section> */}
		</Page>
	);
}

export default HomePage