import React from 'react'
import styled from 'styled-components'

const Staff = ({ members }) => (
  <Wrapper>
    {members.map(member => (
      <Member>
        <Picture src={member.picture} />
        <Info>
          <Name>{member.name}</Name>
          <Role>{member.role}</Role>
          {/* <Extra>{member.email}</Extra> */}
        </Info>
      </Member>
    ))}
  </Wrapper>
)

const Wrapper = styled.div`
  width:100%;
  max-width: 800px;
  margin-bottom: 0px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`

const Member = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 270px;
  margin: 10px 20px;
  padding: 10px;
  background: white;
  
`

const Picture = styled.div`
  display: block;
  width: 180px;
  height: 180px;
  margin: 15px auto;
  background: #888;
  border-radius: 50%;
  background: url(${props => props.src});
  background-position: center;
  background-size: cover;
  filter: grayscale(100%);
  transition: .3s ease filter;

  /* &:hover {
    filter: grayscale(0%);
  } */
`

const Info = styled.div`
  
`

const Name = styled.p`
  font-size: 18px;
  font-weight: 500;
  color: #333;
  text-align: center;
  margin: 0 0 .2em 0;
`

const Role = styled.p`
  font-size: 18px;
  font-weight: 400;
  color: #777;
  text-align: center;
  margin: .2em 0;
`

/* const Extra = styled.p`
  font-size: 16px;
  font-weight: 400;
  color: #777;
  text-align: center;
  margin: .0em 0;
` */

export default Staff