import React from 'react'
import styled from 'styled-components'

const headings = {
  h1: {
    sm: '3.0rem',
    md: '2.2rem'
  },
  h2: {
    sm: '2.0rem',
    md: '1.5rem'
  },
  h3: {
    sm: '1.5rem',
    md: '1.2rem'
  },
}

export const Typography = styled.div`
  font-family: 'Noto Sans', sans-serif;
  color: #333;
`

export const H1 = styled.h1`
  font-size: ${headings.h1.md};
  margin: .5em 0;
`

export const H2 = styled.h2`
  font-size: ${headings.h2.md};
  margin: .5em 0;
`

export const H3 = styled.h2`
  font-size: ${headings.h3.md};
  margin: .5em 0;
`

export const P = styled.p`
  margin: 1em 0;
  line-height: 1.5;
  text-align: justify;
`

export const OL = styled.ol`
  li {
    margin: 1em 0;
  }
`

export const StyledImg = styled.img`
  width: 100%;
  margin: 1.3em 0 .2em 0;
`

export const Caption = styled.p`
  margin: 0 0 1.5em 0;
  text-align: center;
`

export const Img = ({ src, title, caption }) => (
  <div>
    <StyledImg src={src} />
    <Caption>
      <b>{title}</b> - {caption}
    </Caption>
  </div>
)
