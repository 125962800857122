import styled from 'styled-components'

export const HeaderContainer = styled.div`
	max-width: ${props => props.theme.bannerWidth}px;
	margin: 0 auto;
	padding: 0 20px;
	border-bottom: 1px solid #00000000;
	height: ${props => props.theme.headerHeight}px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	box-sizing: border-box;

  @media screen and (max-width: ${props => props.theme.breakpoint}px) {
		flex-direction: column;
		height: auto;
  }
`

export const Logo = styled.h1`
	display: block;
	margin: 0;
	padding: 0;
	color: #347;
	font-weight: bold;
	font-size: 26px;
	letter-spacing: -2px;
`;

export const Top = styled.div`
	height: ${props => props.theme.headerHeight}px;
	display: flex;
	justify-content: space-between;
	align-items: center;

	@media screen and (max-width: ${props => props.theme.breakpoint}px) {
		width: 100%;
  }
`

export const Bottom = styled.div`
	@media screen and (max-width: ${props => props.theme.breakpoint}px) {
		width: 100%;
  }
`

export const Wrapper = styled.div`
	width: 100%;
	height: ${props => props.theme.headerHeight}px;
	position: fixed;
	top: 0;
	left: 0;
	margin-bottom: 0px;
	background: transparent;
	/* background: white; */
	border-bottom: 1px solid transparent;
	/* box-shadow: 0 0 20px -15px rgba(0,0,0,.8); */
	transition: all .3s ease;
	z-index: 100;

	&.active {
		visibility: visible;
	}
	
	&.hidden {
		/* visibility: hidden;
		transform: translate(0, -100%); */
	}

  &.scrolled {
		background: white;
		box-shadow: 0 0 20px -15px rgba(0,0,0,.8);
    /* background: ${props => props.theme.headerBgColor};
		background: white; */
		
		a {
			color: #555;
		}
  }

  @media screen and (max-width: ${props => props.theme.breakpoint}px) {
		background: white;
		width: 100%;
		height: auto;
		position: fixed;
		border-bottom: 1px solid #ddd;

		&.collapsed {
			${Bottom} {
				display: none;
			}
		}
  }
`;