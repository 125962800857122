import React from 'react'
import Container from 'components/Container'
import Staff from 'components/Staff'

import irochimaImg from 'assets/img/staff/irochima.jpg'
import alexImg from 'assets/img/staff/alex.jpg'
import flaviaImg from 'assets/img/staff/flavia.jpg'
import silvanImg from 'assets/img/staff/silvan.jpg'
import pauloImg from 'assets/img/staff/paulo.jpg'

const members = [
  {
    name: 'Dra. Flávia Lana',
    role: 'Médica Oftalmologista',
    picture: flaviaImg,
    email: 'email@email.com',
    phone: ''
  },
  {
    name: 'Dr. Francisco Irochima',
    role: 'Médico Oftalmologista',
    picture: irochimaImg,
    email: 'email@email.com',
    phone: ''
  },
  {
    name: 'Dr. Paulo Schor',
    role: 'Médico Oftalmologista',
    picture: pauloImg,
    email: 'email@email.com',
    phone: ''
  },
  {
    name: 'Silvan Miranda',
    role: 'Desenvolvedor',
    picture: silvanImg,
    email: 'email@email.com',
    phone: ''
  },
  {
    name: 'Alex Garcia',
    role: 'Business',
    picture: alexImg,
    email: 'email@email.com',
    phone: ''
  },
]

const QuemSomosSection = () => (
  <Container>
    <Staff members={members} />
  </Container>
)

export default QuemSomosSection